import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";

export default function TypeChart({ types, allTypes }) {
  const [normalDamageTypes, setNormalDamageTypes] = useState([]);
  const [effectiveDamageTypes, setEffectiveDamageTypes] = useState([]);
  const [resistantDamageTypes, setResistantDamageTypes] = useState([]);
  const [immuneDamageTypes, setImmuneDamageTypes] = useState([]);
  const [effectivenessChart, setEffectivenessChart] = useState();

  useEffect(() => {
    setNormalDamageTypes([]);
    setEffectiveDamageTypes([]);
    setResistantDamageTypes([]);
    setImmuneDamageTypes([]);
  }, [effectivenessChart, types]);

  useEffect(() => {
    let effectivenessMap;

    if (allTypes.length) {
      const initialValue = {};
      effectivenessMap = allTypes.reduce((obj, item) => {
        return {
          ...obj,
          [item.name]: 1,
        };
      }, initialValue);
    }

    types.forEach((type) => {
      fetch(`https://pokeapi.co/api/v2/type/${type.type.name}`)
        .then((response) => response.json())
        .then((data) => {
          checkEffectiveness(data, effectivenessMap);
        })
        .catch((error) => console.error(error));
    });
  }, [allTypes, types]);

  useEffect(() => {
    if (
      effectivenessChart &&
      normalDamageTypes.length === 0 &&
      effectiveDamageTypes.length === 0 &&
      resistantDamageTypes.length === 0 &&
      immuneDamageTypes.length === 0
    ) {
      Object.keys(effectivenessChart).forEach((key) => {
        switch (effectivenessChart[key]) {
          case 1:
            const newNormalArray = normalDamageTypes;
            if (newNormalArray.indexOf(key) === -1) {
              newNormalArray.push({
                name: key,
                multiplier: effectivenessChart[key],
              });
            }
            setNormalDamageTypes([...newNormalArray]);
            break;
          case 2:
          case 4:
            const newEffectiveArray = effectiveDamageTypes;
            if (newEffectiveArray.indexOf(key) === -1) {
              newEffectiveArray.push({
                name: key,
                multiplier: effectivenessChart[key],
              });
            }
            setEffectiveDamageTypes([...newEffectiveArray]);
            break;
          case 0.5:
          case 0.25:
            const newResistantArray = resistantDamageTypes;
            if (newResistantArray.indexOf(key) === -1) {
              newResistantArray.push({
                name: key,
                multiplier: effectivenessChart[key],
              });
            }
            setResistantDamageTypes([...newResistantArray]);
            break;
          case 0:
            const newImmuneArray = immuneDamageTypes;
            if (newImmuneArray.indexOf(key) === -1) {
              newImmuneArray.push({
                name: key,
                multiplier: effectivenessChart[key],
              });
            }
            setImmuneDamageTypes([...newImmuneArray]);
            break;
          default:
            break;
        }
      });
    }
  }, [
    effectivenessChart,
    normalDamageTypes,
    effectiveDamageTypes,
    resistantDamageTypes,
    immuneDamageTypes,
  ]);

  const checkEffectiveness = (type, effectivenessChart) => {
    if (effectivenessChart) {
      const effectiveness = effectivenessChart;

      type.damage_relations.double_damage_from.forEach((type) => {
        effectiveness[type.name] = effectiveness[type.name] * 2;
      });

      type.damage_relations.half_damage_from.forEach((type) => {
        effectiveness[type.name] = effectiveness[type.name] =
          effectiveness[type.name] / 2;
      });

      type.damage_relations.no_damage_from.forEach((type) => {
        effectiveness[type.name] = 0;
      });

      setEffectivenessChart({ ...effectivenessChart });
    }
  };

  return (
    <div className="type-chart">
      <div className="type-section">
        <p>Damaged normally by:</p>
        <div className="types-container">
          {normalDamageTypes.length > 0 ? (
            normalDamageTypes.map((type) => {
              return (
                <span
                  className={`type type-${type.name}`}
                  key={`normal-${type.name}`}
                >
                  {type.name}{" "}
                  <span className="multiplier">
                    <sub>x</sub>
                    {type.multiplier}
                  </span>
                </span>
              );
            })
          ) : (
            <Skeleton width={"100%"} height={120} />
          )}
        </div>
      </div>

      <div className="type-section">
        <p>Weak to:</p>
        <div className="types-container">
          {effectiveDamageTypes.length > 0 ? (
            effectiveDamageTypes.map((type) => {
              return (
                <span
                  className={`type type-${type.name}`}
                  key={`normal-${type.name}`}
                >
                  {type.name}{" "}
                  <span className="multiplier">
                    <sub>x</sub>
                    {type.multiplier}
                  </span>
                </span>
              );
            })
          ) : (
            <Skeleton width={"100%"} height={120} />
          )}
        </div>
      </div>

      <div className="type-section">
        <p>Resist:</p>
        <div className="types-container">
          {resistantDamageTypes ? (
            resistantDamageTypes.map((type, index) => {
              return (
                <span
                  className={`type type-${type.name}`}
                  key={`normal-${type.name}`}
                >
                  {type.name}{" "}
                  <span className="multiplier">
                    <sub>x</sub>
                    {type.multiplier === 0.5 ? "½" : "¼"}
                  </span>
                </span>
              );
            })
          ) : (
            <Skeleton width={"100%"} height={120} />
          )}
        </div>
      </div>

      {immuneDamageTypes.length > 0 && (
        <div className="type-section">
          <p>Immune:</p>
          <div className="types-container">
            {immuneDamageTypes.map((type, index) => {
              return (
                <span
                  className={`type type-${type.name}`}
                  key={`normal-${type.name}`}
                >
                  {type.name}{" "}
                  <span className="multiplier">
                    <sub>x</sub>
                    {type.multiplier}
                  </span>
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
