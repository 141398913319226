import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import makeTitle from "../utilities/makeTitle";
import physical from "../move-physical.png";
import special from "../move-special.png";
import status from "../move-status.png";

export default function Move({ move, isLevelUp = false }) {
  const [moveData, setMoveData] = useState(null);
  const [moveDesc, setMoveDesc] = useState(null);
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const damage_class = {
    physical: physical,
    special: special,
    status: status,
  };

  useEffect(() => {
    setMoveData(null);
  }, [move]);

  useEffect(() => {
    if (!moveData) {
      fetch(`https://pokeapi.co/api/v2/move/${move.name}`)
        .then((response) => response.json())
        .then((data) => {
          setMoveData(data);
          const descSource = data.flavor_text_entries.length
            ? data.flavor_text_entries
            : data.effect_entries;

          const enDesc = descSource.find((desc) => {
            return desc.language.name === "en";
          });
          setMoveDesc(enDesc);
        })
        .catch((error) => console.error(error));
    }
  }, [moveData]);

  if (!moveData)
    return (
      <tr>
        {isLevelUp && (
          <td>
            <Skeleton width={20} height={30} />
          </td>
        )}
        <td>
          <Skeleton width={100} height={30} />
        </td>
        <td>
          <Skeleton width={70} height={30} />
        </td>
        <td>
          <Skeleton width={30} height={30} />
        </td>
        <td>
          <Skeleton width={50} height={30} />
        </td>
        <td>
          <Skeleton width={35} height={30} />
        </td>
      </tr>
    );

  return (
    <tr>
      {isLevelUp && <td>{move.level}</td>}
      <td>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={<p className="tooltip">{moveDesc?.flavor_text}</p>}
            placement="top"
          >
            <span className="move-name" onClick={handleTooltipOpen}>
              {makeTitle(move.name)}
            </span>
          </Tooltip>
        </ClickAwayListener>
      </td>
      <td>
        <span className={`type type-${moveData.type.name}`}>
          {moveData.type.name}
        </span>
      </td>
      <td>
        <img src={damage_class[moveData.damage_class.name]} />
      </td>
      <td align="right">{moveData.power ? moveData.power : "—"}</td>
      <td align="right">{moveData.accuracy ? moveData.accuracy : "—"}</td>
    </tr>
  );
}
