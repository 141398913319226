import React, { useEffect, useState } from "react";

export default function Stats({ stats }) {
  const [totalStats, setTotalStats] = useState(0);

  function getStatName(name) {
    switch (name) {
      case "hp":
        return "HP";
      case "attack":
        return "ATK";
      case "defense":
        return "DEF";
      case "special-attack":
        return "SP ATK";
      case "special-defense":
        return "SP DEF";
      case "speed":
        return "SPD";
      default:
        break;
    }
  }

  useEffect(() => {
    const total = Object.values(stats).reduce((a, b) => {
      return a + b.base_stat;
    }, 0);
    setTotalStats(total);
  }, [stats]);

  return (
    <div className="stats">
      {Object.entries(stats).map(([key, stat]) => {
        return (
          <React.Fragment key={`${stat.stat.name}`}>
            <span className="label">
              {getStatName(stat.stat.name)}{" "}
              <span className="value">{stat.base_stat}</span>
            </span>
            <span className={`meter ${stat.stat.name}`}>
              <span
                style={{ width: `${(stat.base_stat * 100) / 255}%` }}
                className="meter-value"
              ></span>
            </span>
          </React.Fragment>
        );
      })}
      <span className="label">
        Total <span className="value">{totalStats}</span>
      </span>
    </div>
  );
}
