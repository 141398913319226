import { useEffect, useState } from "react";
import makeTitle from "../utilities/makeTitle";

export const TYPE_COLORS = {
  normal: "#a8a878",
  fire: "#f08030",
  water: "#6890f0",
  electric: "#f8d030",
  grass: "#78c850",
  flying: "#a890f0",
  rock: "#b8a038",
  steel: "#b8b8d0",
  ground: "#e0c068",
  bug: "#a8b820",
  poison: "#a040a0",
  ice: "#98d8d8",
  fighting: "#c03028",
  psychic: "#f85888",
  dark: "#705848",
  ghost: "#705898",
  dragon: "#7038f8",
  fairy: "#f0b6bc",
};

export default function EvolutionSpecie({
  specieName,
  setSelectedPokemon,
  currentSpecie,
  currentFormName,
}) {
  const [specieData, setSpecieData] = useState(null);

  useEffect(() => {
    setSpecieData(null);
  }, [specieName, currentSpecie, currentFormName]);

  useEffect(() => {
    if (!specieData) {
      const specieToFetch =
        specieName === currentSpecie ? currentFormName : specieName;
      fetch(`https://pokeapi.co/api/v2/pokemon/${specieToFetch}`)
        .then((response) => response.json())
        .then((data) => {
          setSpecieData(data);
        })
        .catch((error) => console.error(error));
    }
  }, [specieData]);

  return (
    <>
      <p>
        {makeTitle(specieName === currentSpecie ? currentFormName : specieName)}
      </p>
      <button
        style={{
          backgroundImage: `linear-gradient(to bottom, ${
            TYPE_COLORS[specieData?.types[0].type.name]
          }, ${
            TYPE_COLORS[
              specieData?.types[1]
                ? specieData?.types[1].type.name
                : specieData?.types[0].type.name
            ]
          })`,
        }}
        className="evolution-specie"
        onClick={() => setSelectedPokemon(specieName)}
      >
        <img src={specieData?.sprites?.front_default} />
      </button>
    </>
  );
}
