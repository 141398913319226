import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Skeleton } from "@mui/material";

export default function Ability({ ability }) {
  const [abilityDesc, setAbilityDesc] = useState();
  const [abilityName, setAbilityName] = useState();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (ability) {
      fetch(ability.ability.url)
        .then((response) => response.json())
        .then((data) => {
          getAbilitiesInfos(data);
        })
        .catch((error) => console.error(error));
    }
  }, [ability]);

  function getAbilitiesInfos(data) {
    const descSource = data.effect_entries.length
      ? data.effect_entries
      : data.flavor_text_entries;

    const enDesc = descSource.find((effect) => {
      return effect.language.name === "en";
    });

    const enName = data.names.find((effect) => {
      return effect.language.name === "en";
    });

    setAbilityName(enName.name);
    setAbilityDesc(
      data.effect_entries.length ? enDesc.effect : enDesc.flavor_text
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        title={<p className="tooltip">{abilityDesc}</p>}
        placement="top"
      >
        <p onClick={handleTooltipOpen} className="ability">
          {abilityName ? (
            <>
              {abilityName}{" "}
              {ability.is_hidden && <span className="hidden">(hidden)</span>}
            </>
          ) : (
            <Skeleton width={100} height={20} />
          )}
        </p>
      </Tooltip>
    </ClickAwayListener>
  );
}
