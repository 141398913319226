import React, { useCallback, useState } from "react";
import makeTitle from "../utilities/makeTitle";

export default function EvolutionMethod({ evoDetail }) {
  const [evoMethodData, setEvoMethodData] = useState(null);

  const getItemData = async (url) => {
    await fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setEvoMethodData(data);
      });
  };

  const getEvoMethod = useCallback(() => {
    const evoInfo = [];
    if (evoDetail) {
      switch (evoDetail.trigger.name) {
        case "level-up":
          evoInfo.push(<p className="label">Level up</p>);
          if (evoDetail.min_level) {
            evoInfo.push(
              <p className="method">
                Lv. <span className="value">{evoDetail.min_level}</span>
              </p>
            );
          }
          break;
        case "use-item":
          if (!evoMethodData) {
            getItemData(evoDetail.item.url);
          } else {
            const enName = evoMethodData.names.find((name) => {
              return name.language.name === "en";
            });
            evoInfo.push(<p className="label">Item</p>);
            evoInfo.push(
              <div className="method">
                <img className="evo-item" src={evoMethodData.sprites.default} />
                <p className="value">
                  {enName ? enName.name : makeTitle(evoMethodData.name)}
                </p>
              </div>
            );
          }
          break;
        case "trade":
          evoInfo.push(<p className="label">Trade</p>);
          if (!evoDetail.held_item && !evoDetail.trade_species) {
            evoInfo.push(<p className="method">No item</p>);
          }
          break;
        case "spin":
          evoInfo.push(<p className="label">Spin</p>);
          evoInfo.push(<p className="method">Spin and strike a pose</p>);
          break;
        case "shed":
          evoInfo.push(<p className="label">Party</p>);
          evoInfo.push(
            <p className="method">Have an empty spot in your party</p>
          );
          break;
        case "other":
          evoInfo.push(<p className="label">Other</p>);
          evoInfo.push(<p>(ex. steps count)</p>);
        default:
          break;
      }

      if (evoDetail.gender === 1) {
        evoInfo.push(<p className="method">Must be female</p>);
      }

      if (evoDetail.gender === 2) {
        evoInfo.push(<p className="method">Must be male</p>);
      }

      if (evoDetail.min_happiness) {
        evoInfo.push(
          <p className="method">
            Happiness: <span className="value">{evoDetail.min_happiness}</span>
          </p>
        );
      }

      if (evoDetail.min_affection) {
        evoInfo.push(
          <p className="method">
            Affection:
            <span className="value">{evoDetail.min_affection}</span>
          </p>
        );
      }

      if (evoDetail.min_beauty) {
        evoInfo.push(
          <p className="method">
            Beauty:
            <span className="value">{evoDetail.min_beauty}</span>
          </p>
        );
      }

      if (evoDetail.time_of_day) {
        evoInfo.push(
          <p className="method">At {makeTitle(evoDetail.time_of_day)}</p>
        );
      }

      if (evoDetail.known_move_type) {
        evoInfo.push(
          <p className="method">
            While learning move:{" "}
            <span className="value">{evoDetail.known_move_type.name}</span>
          </p>
        );
      }

      if (evoDetail.location) {
        evoInfo.push(
          <p className="method">
            At{" "}
            <span className="value">{makeTitle(evoDetail.location.name)}</span>
          </p>
        );
      }

      if (evoDetail.needs_overworld_rain) {
        evoInfo.push(<p className="method">While raining</p>);
      }

      if (evoDetail.party_species) {
        evoInfo.push(
          <p className="method">
            While having a{" "}
            <span className="value">
              {makeTitle(evoDetail.party_species.name)}
            </span>{" "}
            in your party
          </p>
        );
      }

      if (evoDetail.held_item) {
        if (!evoMethodData) {
          getItemData(evoDetail.held_item.url);
        } else {
          const enName = evoMethodData.names.find((name) => {
            return name.language.name === "en";
          });

          evoInfo.push(
            <div className="method">
              <p className="evo-method-name">
                With <span className="value">{enName?.name}</span>
              </p>
              <img className="evo-item" src={evoMethodData.sprites.default} />
            </div>
          );
        }
      }

      if (evoDetail.trade_species) {
        evoInfo.push(
          <div className="method">
            <p className="evo-method-name">
              With{" "}
              <span className="value">
                {makeTitle(evoDetail.trade_species.name)}
              </span>
            </p>
          </div>
        );
      }

      if (evoDetail.turn_upside_down) {
        evoInfo.push(<p className="method">While 3ds is upside down</p>);
      }

      if (evoDetail.relative_physical_stats !== null) {
        if (evoDetail.relative_physical_stats === 1) {
          evoInfo.push(<p className="method">While ATK {">"} DEF</p>);
        }
        if (evoDetail.relative_physical_stats === -1) {
          evoInfo.push(<p className="method">While ATK {"<"} DEF</p>);
        }
        if (evoDetail.relative_physical_stats === 0) {
          evoInfo.push(<p className="method">While ATK {"="} DEF</p>);
        }
      }

      return evoInfo;
    }
  }, [evoMethodData]);

  return (
    <div className="evolution-method">
      {getEvoMethod()?.map((info, index) => {
        return <React.Fragment key={`method-${index}`}>{info}</React.Fragment>;
      })}
    </div>
  );
}
