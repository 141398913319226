import { useEffect, useState } from "react";
import TypeChart from "./TypeChart";
import PokedexData from "./PokedexData";
import EvolutionChain from "./EvolutionChain";
import Stats from "./Stats";
import { Skeleton, Tab, Tabs } from "@mui/material";
import Moves from "./Moves";
import EvolutionSpecie from "./EvolutionSpecie";
import PokemonForm from "./PokemonForm";

export default function Pokemon({ pokemonData, allTypes, setSelectedPokemon }) {
  const { sprites, types } = pokemonData;
  const [specieData, setSpecieData] = useState(null);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const url = pokemonData.species.url;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setSpecieData(data);
      })
      .catch((error) => console.error(error));
  }, [pokemonData]);

  const stats = pokemonData?.stats || [];
  const moves = pokemonData?.moves || [];

  return (
    <div>
      <div className="main-section">
        <img
          className="pokemon-img"
          src={sprites.other["official-artwork"].front_default}
        />
        {(specieData?.varieties?.length > 1 ||
          pokemonData?.forms?.length > 1) && (
          <div className="forms">
            {specieData?.varieties?.length > 1 &&
              specieData.varieties.map((form) => {
                return (
                  <PokemonForm
                    key={form.pokemon.name}
                    isCurrent={form.pokemon.name === pokemonData?.name}
                    setSelectedPokemon={setSelectedPokemon}
                    specieName={form.pokemon.name}
                  />
                );
              })}
            {pokemonData?.forms?.length > 1 &&
              pokemonData.forms.map((form) => {
                return (
                  <PokemonForm
                    key={form.name}
                    isCurrent={form.name === pokemonData?.name}
                    setSelectedPokemon={setSelectedPokemon}
                    specieName={form.name}
                  />
                );
              })}
          </div>
        )}
      </div>
      <Tabs
        className="tabs"
        value={value}
        onChange={(e, newValue) => setValue(newValue)}
      >
        <Tab label="Infos" />
        <Tab label="Stats" />
        <Tab label="Evolution chain" />
        <Tab label="Type chart" />
        <Tab label="Moves" />
      </Tabs>
      {value === 0 && (
        <PokedexData specieData={specieData} pokemonData={pokemonData} />
      )}
      {value === 1 && <Stats stats={stats} />}
      {value === 2 && (
        <div className="evolution-chain">
          {specieData && (
            <EvolutionChain
              currentSpecie={specieData?.name}
              currentFormName={pokemonData?.name}
              setSelectedPokemon={setSelectedPokemon}
              evolutionUrl={specieData.evolution_chain.url}
            />
          )}
        </div>
      )}
      {value === 3 && (
        <div className="weaknesses">
          <TypeChart allTypes={allTypes} types={types} />
        </div>
      )}
      {value === 4 && <Moves moves={moves} />}
    </div>
  );
}
