import React, { useEffect, useState } from "react";
import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Pokemon from "./components/Pokemon";

function App() {
  const [allTypes, setAllTypes] = useState([]);
  const [pokemonList, setPokemonList] = useState([]);
  const [selectedPokemon, setSelectedPokemon] = useState(null);
  const [pokemonData, setPokemonData] = useState();

  useEffect(() => {
    fetch("https://pokeapi.co/api/v2/pokemon?limit=1025&offset=0")
      .then((response) => response.json())
      .then((data) => {
        const resultsList = data.results.map((pokemon) => {
          return pokemon.name;
        });
        setPokemonList([...resultsList]);
      })
      .catch((error) => console.error(error));

    fetch("https://pokeapi.co/api/v2/type?limit=18")
      .then((response) => response.json())
      .then((data) => {
        setAllTypes([...data.results]);
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    if (selectedPokemon) {
      fetch(`https://pokeapi.co/api/v2/pokemon/${selectedPokemon}`)
        .then((response) => response.json())
        .then((data) => {
          setPokemonData(data);
        })
        .catch((error) => console.error(error));
    }
  }, [selectedPokemon]);

  return (
    <div className="App">
      <header className="App-header">
        <Autocomplete
          disablePortal
          onChange={(e, value) => setSelectedPokemon(value)}
          id="autocomplete"
          options={pokemonList}
          sx={{ width: 300 }}
          value={selectedPokemon}
          renderInput={(params) => <TextField {...params} label="Pokémon" />}
        />
      </header>

      {pokemonData && (
        <Pokemon
          setSelectedPokemon={setSelectedPokemon}
          allTypes={allTypes}
          pokemonData={pokemonData}
        />
      )}
    </div>
  );
}

export default App;
