import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Move from "./Move";

export default function Moves({ moves }) {
  const [selectedGame, setSelectedGame] = useState(null);

  const levelUpByRegion = {};
  const tmByRegion = {};
  const otherByRegion = {};

  useEffect(() => {
    setSelectedGame(null);
  }, [moves]);

  // Iterate over the moves array
  moves.forEach((move) => {
    move.version_group_details.forEach((detail) => {
      const region = detail.version_group.name;
      if (detail.move_learn_method.name === "level-up") {
        if (!levelUpByRegion[region]) {
          levelUpByRegion[region] = new Set();
        }

        levelUpByRegion[region].add({
          name: move.move.name,
          level: detail.level_learned_at,
          url: detail.move_learn_method.url,
        });
      } else if (detail.move_learn_method.name === "machine") {
        if (!tmByRegion[region]) {
          tmByRegion[region] = new Set();
        }

        tmByRegion[region].add({
          name: move.move.name,
          url: detail.move_learn_method.url,
        });
      } else {
        if (!otherByRegion[region]) {
          otherByRegion[region] = new Set();
        }

        otherByRegion[region].add({
          name: move.move.name,
          url: detail.move_learn_method.url,
        });
      }
    });
  });

  Object.keys(levelUpByRegion).forEach((key) => {
    levelUpByRegion[key] = [...levelUpByRegion[key]].sort((a, b) => {
      if (a.level < b.level) return -1;
      if (a.level > b.level) return 1;
      return 0;
    });
  });

  return (
    <div className="moves">
      <div className="select">
        <Autocomplete
          disablePortal
          onChange={(e, value) => setSelectedGame(value)}
          id="autocomplete"
          options={Object.keys(levelUpByRegion).sort()}
          sx={{ width: 300 }}
          value={selectedGame}
          renderInput={(params) => (
            <TextField {...params} label="Choose a game" />
          )}
        />
      </div>
      {selectedGame && (
        <div className="move-tables">
          <div className="by-level">
            <p className="title">Moves learned by level up</p>
            <table>
              <thead>
                <tr>
                  <td>Lv.</td>
                  <td>Move</td>
                  <td>Type</td>
                  <td>Cat.</td>
                  <td align="right">Power</td>
                  <td align="right">Acc.</td>
                </tr>
              </thead>
              <tbody>
                {selectedGame &&
                  levelUpByRegion[selectedGame] &&
                  [...levelUpByRegion[selectedGame]].map((move) => {
                    return (
                      <Move
                        key={`level-${move.name}`}
                        move={move}
                        isLevelUp={true}
                      />
                    );
                  })}
              </tbody>
            </table>
          </div>
          {tmByRegion[selectedGame] && (
            <div className="by-tm">
              <p className="title">Moves learned by tm</p>
              <table>
                <thead>
                  <tr>
                    <td>Move</td>
                    <td>Type</td>
                    <td>Cat.</td>
                    <td align="right">Power</td>
                    <td align="right">Acc.</td>
                  </tr>
                </thead>
                <tbody>
                  {[...tmByRegion[selectedGame]].map((move) => {
                    return (
                      <Move
                        key={`tm-${move.name}`}
                        move={move}
                        selectedGame={selectedGame}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {otherByRegion[selectedGame] && (
            <div className="other">
              <p className="title">Moves learned by other methods</p>
              <table>
                <thead>
                  <tr>
                    <td>Move</td>
                    <td>Type</td>
                    <td>Cat.</td>
                    <td align="right">Power</td>
                    <td align="right">Acc.</td>
                  </tr>
                </thead>
                <tbody>
                  {[...otherByRegion[selectedGame]].map((move) => {
                    return <Move key={`other-${move.name}`} move={move} />;
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
