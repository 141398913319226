import { useEffect, useState } from "react";
import { TYPE_COLORS } from "./EvolutionSpecie";

export default function PokemonForm({
  specieName,
  setSelectedPokemon,
  isCurrent,
}) {
  const [specieData, setSpecieData] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSpecieData(null);
  }, [specieName]);

  useEffect(() => {
    if (!specieData) {
      fetch(`https://pokeapi.co/api/v2/pokemon/${specieName}`)
        .then((response) => response.json())
        .then((data) => {
          setSpecieData(data);
        })
        .catch((error) => setError(true));
    }
  }, [specieData]);

  if (error || !specieData) return;

  return (
    <button
      style={{
        backgroundImage: `linear-gradient(to bottom, ${
          TYPE_COLORS[specieData?.types[0].type.name]
        }, ${
          TYPE_COLORS[
            specieData?.types[1]
              ? specieData?.types[1].type.name
              : specieData?.types[0].type.name
          ]
        })`,
      }}
      disabled={isCurrent}
      className={`form evolution-specie ${isCurrent ? "current" : ""}`}
      onClick={() => setSelectedPokemon(specieName)}
    >
      <img src={specieData?.sprites.other["official-artwork"].front_default} />
    </button>
  );
}
