import makeTitle from "../utilities/makeTitle";
import Ability from "./Ability";

export default function PokedexData({ specieData, pokemonData }) {
  if (!specieData) return null;

  return (
    <div className="infos">
      <div>
        <p className="label">Dex number</p>
        <p className="value">{specieData.id.toString().padStart(4, "0")}</p>
      </div>
      <div className="types-container">
        <p className="label">Types</p>
        <p className="value">
          {pokemonData.types.map((type) => (
            <span
              key={type.type.name}
              className={`type type-${type.type.name}`}
            >
              {type.type.name}
            </span>
          ))}
        </p>
      </div>
      <div>
        <p className="label">Catch rate</p>
        <p className="value">{specieData.capture_rate}</p>
      </div>
      <div>
        <p className="label">Base happiness</p>
        <p className="value">{specieData.base_happiness}</p>
      </div>
      <div className="abilities-container">
        <p className="label">Abilities</p>
        {pokemonData.abilities.map((ability) => (
          <Ability key={ability.ability.name} ability={ability} />
        ))}
      </div>
      <div>
        <p className="label">Growth Rate</p>
        <p className="value">{makeTitle(specieData.growth_rate.name)}</p>
      </div>
    </div>
  );
}
