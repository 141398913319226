import { useEffect, useState } from "react";
import EvolutionSpecie from "./EvolutionSpecie";
import EvolutionMethod from "./EvolutionMethod";
import { Skeleton } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function EvolutionChain({
  evolutionUrl,
  setSelectedPokemon,
  currentSpecie,
  currentFormName,
}) {
  const [chainData, setChainData] = useState(null);

  useEffect(() => {
    setChainData(null);
  }, [evolutionUrl]);

  useEffect(() => {
    if (!chainData) {
      fetch(evolutionUrl)
        .then((response) => response.json())
        .then((data) => {
          const chain = data.chain;
          setChainData(chain);
        })
        .catch((error) => console.error(error));
    }
  }, [chainData]);

  function renderChain(chain) {
    return (
      <div className="specie" key={chain.species.name}>
        <EvolutionSpecie
          currentSpecie={currentSpecie}
          currentFormName={currentFormName}
          setSelectedPokemon={setSelectedPokemon}
          specieName={chain.species.name}
        />
        <div className="evolutions">
          {chain.evolves_to.map((evo, index) => (
            <div key={`evo-${index}`} className="evolution">
              <Swiper
                spaceBetween={20}
                allowTouchMove={false}
                slidesPerView={1}
                autoHeight
                navigation
                pagination
                modules={[Navigation, Pagination]}
                className="evolution-methods-slider"
              >
                {evo.evolution_details.map((detail) => (
                  <SwiperSlide>
                    <EvolutionMethod evoDetail={detail} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {renderChain(evo)}
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      {chainData ? (
        renderChain(chainData)
      ) : (
        <Skeleton width={600} height={600} />
      )}
    </div>
  );
}
